.MuiInputBase-input {
  font-size: 13px !important;
}
*/ .MuiPaper-elevation2 {
  box-shadow: 1px 1px 12px 0 !important;
}

.dahborad-headline {
  text-align: center;
  margin-top: 1%;
  padding-bottom: 2%;
}

.sales-dashboard-tab1 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.ml-5,
.mx-5 {
  margin-left: 6rem !important;
}

.mr-3,
.mx-3 {
  margin-right: -5.5rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 6rem !important;
}

@media only screen and (max-width: 1250px) {
  .sales-dashboard-tab1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mr-3 {
    margin-right: -0.5rem !important;
  }

  .ml-5 {
    margin-left: 1rem !important;
  }

  .ml-1 {
    margin-left: 0rem !important;
  }
}
@media only screen and (min-width: 1251px) and (max-width: 1320px) {
  .sales-dashboard-tab1 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
