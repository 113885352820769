  .btnConfirmAll {
    padding: 0.4rem 1rem;
    border-radius: 5px;
    background-color: #f0ad4e;
    border-color: #eea236;
    box-shadow: none;
    color: white;
    font-size: 14px;
    font-weight: 800;
  }

